.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  padding: 1rem 0;
  background-color: #f5f6fa;
}

/* Common styles for all sections */
.section {
  margin: 0.5rem 0;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section h2 {
  color: #2c5282;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  text-align: center;
}

/* Header styles */
.App-header {
  background-color: #2c3e50;
  color: white;
  padding: 1.5rem;
  text-align: center;
}

.App-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.App-header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.App-header nav a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.App-header nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Common styles for content containers */
.neighborhoods-content,
.housing-content,
.mikvaot-content,
.kosher-food-content,
.networking-content,
.news-content,
.schools-content,
.visiting-content,
.resources-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

/* Common styles for info containers */
.neighborhood,
.housing-info,
.mikvah-info,
.kosher-restaurants,
.kosher-groceries,
.kosher-catering,
.networking-info,
.news-info,
.schools-info,
.visiting-info,
.resources-info {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Common styles for headings */
.neighborhood h3,
.housing-info h3,
.mikvah-info h3,
.kosher-restaurants h3,
.kosher-groceries h3,
.kosher-catering h3,
.networking-info h3,
.news-info h3,
.schools-info h3,
.visiting-info h3,
.resources-info h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

/* Common styles for paragraphs */
.neighborhood p,
.housing-info p,
.mikvah-info p,
.kosher-restaurants p,
.kosher-groceries p,
.kosher-catering p,
.networking-info p,
.news-info p,
.schools-info p,
.visiting-info p,
.resources-info p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-size: 1rem;
}

/* Common styles for lists */
.neighborhood ul,
.housing-info ul,
.mikvah-info ul,
.kosher-restaurants ul,
.kosher-groceries ul,
.kosher-catering ul,
.networking-info ul,
.news-info ul,
.schools-info ul,
.visiting-info ul,
.resources-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Common styles for list items */
.neighborhood li,
.housing-info li,
.mikvah-info li,
.kosher-restaurants li,
.kosher-groceries li,
.kosher-catering li,
.networking-info li,
.news-info li,
.schools-info li,
.visiting-info li,
.resources-info li {
  color: #4a5568;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  line-height: 1.6;
  font-size: 1rem;
}

/* Common styles for list items with bullet points */
.housing-info li:before,
.mikvah-info li:before,
.kosher-restaurants li:before,
.kosher-groceries li:before,
.kosher-catering li:before,
.networking-info li:before,
.news-info li:before,
.schools-info li:before,
.visiting-info li:before,
.resources-info li:before {
  content: "•";
  color: #3498db;
  position: absolute;
  left: 0;
  margin-left: -1rem;
}

/* Common styles for last list items */
.neighborhood li:last-child,
.housing-info li:last-child,
.mikvah-info li:last-child,
.kosher-restaurants li:last-child,
.kosher-groceries li:last-child,
.kosher-catering li:last-child,
.networking-info li:last-child,
.news-info li:last-child,
.schools-info li:last-child,
.visiting-info li:last-child,
.resources-info li:last-child {
  border-bottom: none;
}

/* Common styles for links */
a {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: #2c5282;
  text-decoration: underline;
}

/* Specific styles for the map container */
.map-container {
  margin: 1rem 0;
  padding: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.map-container h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.map-container p {
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: center;
}

.map-frame {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Introduction section styles */
.introduction {
  padding: 2rem 0;
  background-color: #f8f9fa;
}

.introduction-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.introduction-image-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.introduction-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introduction-text {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.section-divider {
  border: none;
  border-top: 1px solid #e2e8f0;
  margin: 1.5rem 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .App-header {
    padding: 1rem;
  }

  .App-header h1 {
    font-size: 2rem;
  }

  .App-header nav ul {
    flex-direction: column;
    gap: 0.5rem;
  }

  .neighborhoods-content,
  .housing-content,
  .mikvaot-content,
  .kosher-food-content,
  .networking-content,
  .news-content,
  .schools-content,
  .visiting-content,
  .resources-content {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0.5rem;
  }

  .introduction {
    padding: 1rem 0;
  }

  .introduction-content {
    gap: 0.5rem;
    padding: 0 0.5rem;
  }

  .introduction-image-container {
    margin-bottom: 0.5rem;
  }

  .introduction-text {
    margin-top: 0.5rem;
  }
}

.support-options {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.support-options li {
  margin: 0.5rem 0;
  padding: 0;
}

.ad-container {
  min-height: 250px;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  transition: min-height 0.3s ease;
}

.adsbygoogle {
  display: block;
  text-align: center;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
}

/* Hide placeholder when ad is loaded */
.adsbygoogle[data-adsbygoogle-status="done"] {
  background: none;
  min-height: 0;
}

/* Responsive adjustments for ads */
@media (max-width: 768px) {
  .adsbygoogle {
    margin: 1.5rem auto;
    padding: 0.75rem;
  }
}

/* Make first two neighborhoods span full width */
.neighborhood:nth-child(1),
.neighborhood:nth-child(2) {
  grid-column: 1 / -1;
}

/* Last three neighborhoods stay in their own columns */
.neighborhood:nth-child(3),
.neighborhood:nth-child(4),
.neighborhood:nth-child(5) {
  grid-column: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .neighborhoods-content {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .neighborhood:nth-child(1),
  .neighborhood:nth-child(2),
  .neighborhood:nth-child(3),
  .neighborhood:nth-child(4),
  .neighborhood:nth-child(5) {
    grid-column: auto;
  }
}
